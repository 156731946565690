import {
    Paper,
    Title,
    Text,
    Container,
    FileButton,
    Button,
    NativeSelect,
    Center,
    Stack,
  } from '@mantine/core';
import classes from '../styles/PickFlightPage.module.css';
import { useCallback, useEffect, useState } from 'react';
import { useLoadData, loadFileFromPublic, loadPickedFile } from '../hooks/useLoadData.jsx';
import { useNavigate } from 'react-router';
import {useGlobalStore} from '../zustandStore.js'

export function PickFlightPage() {
    const loadData = useLoadData();

    const dataStore = useGlobalStore((state) => state.dataStore);
    const resetDataStore = useGlobalStore((state) => state.resetDataStore);

    const [demos, setDemos] = useState(null);

    useEffect(() => {
        const fetchJsonData = async () => {
        try {
            const res = await fetch(`/available_demos.json`)
            const result = await res.json();
            setDemos(result);
        } catch (error) {
            console.error('Error fetching JSON data:', error);
            setDemos([])
        }
        };

        fetchJsonData();
    },[])

    const navigate = useNavigate();  
    const redirect = useCallback(() => navigate("replay"), [navigate])

    if (!demos) return <></>

    return (
        <Container size={500} my={150}>
        <Paper withBorder shadow="md" w={400} p={20} radius="xl">
            <Stack gap="xl">
            <Title ta="center" className={classes.title}>
            Welcome to <br/>BLK2FLY-Replay!
            </Title>

            <Center>
                <FileButton onChange={
                    filePath => loadPickedFile(filePath).then(result => loadData(result))
                    } 
                    accept=".json.gz" 
                    size="xl">
                {(props) => <Button {...props}>Upload replay</Button>}
                </FileButton>
            </Center>

            <Text size="lg" ta="center">Or choose a demo below</Text>

            <Center>
            <NativeSelect 
            onChange={(event) => {
                const val = event.currentTarget.value
                if (val === 'None') {
                    resetDataStore();
                } else {
                    loadFileFromPublic(demos[val]).then(result => loadData(result));
                }
            }}
            data={Object.keys(demos)}
            />
            </Center>

            <Button fullWidth disabled={dataStore === null} onClick={redirect}>
            Let's fly!
            </Button>
            </Stack>
        </Paper>
        </Container>
    );
}