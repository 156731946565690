import { useRef, useCallback, useMemo } from 'react'
import { useGLTF } from '@react-three/drei'
import {Interpolator} from './helpers'
import { buttonGroup, useControls } from 'leva'
import { useFrame } from '@react-three/fiber'
import {useGlobalStore} from './zustandStore.js'

import oriole_model from './assets/oriole-transformed-arrow.glb'

let corrected_orienation = false

export function OrioleModel({ts, trajectoryPoints, quaternions}) {
    const { nodes } = useGLTF(oriole_model)

    const setGlobalTs = useGlobalStore((state) => state.setGlobalTs);
    const dataStore = useGlobalStore((state) => state.dataStore);
    const drone = useGlobalStore((state) => state.drone);
    const focusDrone = useGlobalStore((state) => state.focusDrone);
    const isRunning = useGlobalStore((state) => state.isRunning);
    const setIsRunning = useGlobalStore((state) => state.setIsRunning);
    const speed = useGlobalStore((state) => state.speed);
    const setSpeed = useGlobalStore((state) => state.setSpeed);
    const followDrone = useGlobalStore((state) => state.followDrone);

    // after loading we need to rotate the model, because it is facing in the wrong direction
    if (!corrected_orienation) {
        nodes.node.geometry.rotateZ(Math.PI)
        corrected_orienation = true
    }

    const interpolator = useRef(null);
    if (interpolator.current === null) {
        interpolator.current = new Interpolator(ts, trajectoryPoints, quaternions);
    }

    const [, set_running] = useControls(() => ({
        running: {
            label: "Start/Stop",
            value: isRunning,
            onChange: value => setIsRunning(value),
        },
    }), [setIsRunning]);

    const [, updateSlider] = useControls(() => ({
        time: {
            label: "Time",
            value: ts[0],
            min: ts[0],
            max: ts[ts.length - 1],
            step: 1,
            onChange: value => {
                // change came from useFrame loop
                if (value === interpolator.current.getTime()) {
                    return;
                }
        
                interpolator.current.setTime(value, drone.current);
                setGlobalTs(value, true);
            },
            onEditStart: () => {
                set_running({running: false})
            },
        },
    }), [setGlobalTs, set_running])

    useControls(() => ({
        speed: {
            label: "Speed",
            value: speed,
            min: 0.1,
            max: 10,
            step: 0.1,
            onChange: value => {
                setSpeed(value)
            }
        }
    }), [setSpeed]);

    const buttonTimeJump = useCallback((duration) => {
        const new_ts = interpolator.current.getTime() + duration;
        setGlobalTs(new_ts, true);
        updateSlider({time: new_ts});
    }, [setGlobalTs, updateSlider]);

    useControls(() => ({
        timeButtonGroup: buttonGroup({
            label: "Jump",
            opts: {
                '-10s': () => buttonTimeJump(-10),
                '-5s': () => buttonTimeJump(-5),
                '+5s': () => buttonTimeJump(5),
                '+10s': () => buttonTimeJump(10),
              },
        }),
    }))

    const omp_states = dataStore?.fsm?.data;
    const enumDescriptor = dataStore?.enums;
    const event_list = useMemo(() => {
        if (!omp_states || !enumDescriptor) {
            console.warn("No states or enum descriptor found!")
            return {}
        }

        const ret = {}
        for (const [ts, state, mission] of omp_states) {
            const seconds = ts / 1e9

            // we skip idle, nothing interesting happens in idle
            if (state === 40) {
                continue
            }

            
            let state_text = enumDescriptor["omp_node_base.fsm_state_id"][state]
            // we are doing a mission
            if (mission) {
                state_text = enumDescriptor["omp_node_base.omp_data.input.current_mission.type"][mission]
            }
            const txt = `${seconds.toFixed(0)} - ${state_text}`
            ret[txt] = seconds
        }
        return ret
    }, [enumDescriptor, omp_states]);

    useControls(() => ({
        navigate: {
            label: "Jump to",
            options: event_list,
            onChange: (seconds) => {
                focusDrone();
                interpolator.current.setTime(seconds, drone.current);
                setGlobalTs(seconds, true);
                updateSlider({time: seconds});
            },
        },
    }), [updateSlider, focusDrone])

    // allows us to render UI with lower frequency
    let frameCounter = 0;
    // this is the game loop, runs the animations
    useFrame((_, delta) => {
        if (!isRunning || !drone) {
            return;
        }
        
        const success = interpolator.current.advance(speed * delta, drone.current);
        if (!success) {
            set_running({running: false});
        }

        frameCounter += 1;
        if (frameCounter % 10 === 0) {
            let current_ts = interpolator.current.getTime()
            setGlobalTs(current_ts, false);
            updateSlider({time: current_ts});
            if (followDrone) {
                focusDrone();
            }
            frameCounter = 0;
        }
    })

  return (
      <mesh 
      ref={drone} 
      name={"blk2fly"} 
      geometry={nodes.node.geometry} 
      material={nodes.node.material} 
      dispose={null}

      // makes the drone visible behind occluded places, good in case of double ground
      material-depthTest={false}
      material-depthWrite={false}
      material-transparent={true}
      />
  )
}

// preloads the model of the drone before rendering the component
// speeds up the loading of the application
useGLTF.preload(oriole_model)
