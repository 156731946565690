import { useCallback } from 'react';
import { decompressSync, strFromU8} from 'fflate';
import enumDescriptor from '../assets/omp_node.enums.json'
import {useGlobalStore} from '../zustandStore.js'


function decompressReplay(compressedContents) {
  const compressedIn = new Uint8Array(compressedContents);
  const unzipContents = strFromU8(decompressSync(compressedIn));
  return JSON.parse(unzipContents);
}

export async function loadPickedFile(selectedFile, abortSignal) {
  return new Promise((resolve, reject) => {
    if (abortSignal) {
      abortSignal.onabort = () => reader.abort();
    }

    const reader = new FileReader();
    reader.onload = function (e) {
      resolve(e.target.result)
    };
    reader.onerror = reject
    reader.readAsArrayBuffer(selectedFile)
  })
}

export async function loadFileFromPublic(selectedFile, abortSignal) {
  try {
    const response = await fetch("/" + selectedFile, {signal: abortSignal});

    // During devevlopment vite automatically decompresses the replay
    // in prod when the data arrives compressed, the Content-Type is octet-stream
    const contentType = response.headers.get('Content-Type');
    if (contentType === "application/octet-stream") {
      const arraybuffer = await response.arrayBuffer();
      return arraybuffer;
    } else {
      const jsondata = await response.json();
      return jsondata;
    }
  } catch(error) {
    console.error('There was a problem with the fetch operation:', error);
  }
}

export function useLoadData() {
  const setDataStore = useGlobalStore((state) => state.setDataStore);
  const setGlobalTs = useGlobalStore((state) => state.setGlobalTs);

  const loadData = useCallback((data) => {
    try {
      if (data instanceof ArrayBuffer) {
        data =  decompressReplay(data);
      }
      
      console.log(`Main map size: ${data?.main_map?.data?.length} points`);

      if (!data.enums) {
        data.enums = enumDescriptor
        console.warn("No enums descriptor found in replay, falling back to default descriptor!")
      }
      setDataStore(data);

      const first_ts = data?.trajectory?.data?.[0]?.[0] / 1e9;

      setGlobalTs(first_ts, true);
    } catch (error) {
      alert(`Failed to load data: ${error}`, data);
    }
  }, [setDataStore, setGlobalTs]);

  return loadData;
}
