import React from 'react';
import {useGlobalStore} from '../zustandStore.js'
import Chart from 'react-apexcharts'
import { formatTs, getStartAndEnd } from './util';

const gnss_bbvar = "omp_node_base.omp_data.input.gnss_data.fix";
const rtk_bbvar = "omp_node_base.omp_data.input.gnss_data.rtk";

const options = {
    chart: {
        type: 'rangeBar',
        group: 'gnss',
        id : 'state'
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        if (!data) return;

        let tooltip = `${data.x}: ${data.y[0]}s - ${data.y[1]}s`;
        return tooltip;
      }
    },
    xaxis: {
      title: {
        text: 'Timestamp [s]'
      }
    },
    yaxis: {
        labels: {
          minWidth: 100,
          maxWidth: 100,
        }
    },
};

const options_satellites = {
    chart: {
        type: 'line',
        height: 350,
        group: "gnss",
        id: 'satellites'
    },
    stroke: {
        curve: 'stepline',
    },
    title: {
        text: 'Number of Satellites',
        align: 'center'
    },
    xaxis: {
        type: 'numeric',
        title: {
            text: 'Timestamp [s]'
        }
    },
    yaxis: {
        labels: {
          minWidth: 100,
          maxWidth: 100,
        }
    },
};

function GpsChart() {
    const dataStore = useGlobalStore((state) => state.dataStore);

    const enumDescriptor = dataStore?.enums;
    const gpsData = dataStore?.gps?.data;
    if  (!enumDescriptor || !gpsData) return <></>;

    const limits = getStartAndEnd(dataStore)
    options.xaxis = {...options.xaxis, ...limits}
    options_satellites.xaxis = {...options_satellites.xaxis, ...limits}

    // marshal the input states
    const gnss_state = [];
    const rtk_state = [];
    const series_satellites = [
        {name: "Number of Satellites", data: []},
    ];
    for (let i = 0; i < gpsData.length; i++) {
        const [ts_raw, fix, rtk, sat] = gpsData[i];
        const ts = formatTs(ts_raw);

        const last_element = (i === gpsData.length - 1);
        // if this is the last element, we take the end of the series
        const ts_end = (!last_element) ? ts : limits.max;

        // gnss fix
        if (gnss_state.length) {
            gnss_state.at(-1).end = ts;
        }
        if (!gnss_state.length || gnss_state.at(-1).type !== fix) {
            gnss_state.push({type: fix, start: ts, end: ts_end});
        }

        // rtk fix
        if (rtk_state.length) {
            rtk_state.at(-1).end = ts;
        }
        if (!rtk_state.length || rtk_state.at(-1).type !== rtk) {
            rtk_state.push({type: rtk, start: ts, end: ts_end});
        }

        series_satellites[0].data.push([ts, sat]);

        if (last_element) {
            gnss_state.at(-1).end = ts_end;
            rtk_state.at(-1).end = ts_end;
            // push in the last gnss point, so we can form a line
            series_satellites[0].data.push([ts_end, sat]);
        }
    }

    // create gnss chart
    const series_gnss = [{data: []}]
    for (const {type, start, end} of gnss_state) {
        series_gnss[0].data.push({
            x: enumDescriptor[gnss_bbvar][type],
            y: [start, end]
        });
    }

    // create RTK chart
    const series_rtk = [{data: []}]
    for (const {type, start, end} of rtk_state) {
        series_rtk[0].data.push({
            x: enumDescriptor[rtk_bbvar][type],
            y: [start, end]
        });
    }

    return <>
    <Chart options={{...options, title: {text: "GPS State", align: "center"}}} series={series_gnss} type="rangeBar" height={200}  />
    <Chart options={{...options, title: {text: "RTK State", align: "center"}}} series={series_rtk} type="rangeBar" height={200}  />
    <Chart options={options_satellites} series={series_satellites} type="line" height={350}  />
    </>
}

export default GpsChart;
