import { useCallback} from 'react';

import {CameraControls} from "@react-three/drei";
import ThreeCameraControls from 'camera-controls';
import * as holdEvent from 'hold-event';
import { MathUtils } from 'three';
import { useControls, button, folder} from 'leva'
import {useGlobalStore} from './zustandStore.js'
import { useEventListener } from './hooks/useEventListener.jsx'

const KEY_CODES = {
    W: "KeyW",
    A: "KeyA",
    S: "KeyS",
    D: "KeyD",
    ARROW_LEFT: "ArrowLeft",
    ARROW_UP: "ArrowUp",
    ARROW_RIGHT: "ArrowRight",
    ARROW_DOWN: "ArrowDown"
};

const wKey = new holdEvent.KeyboardKeyHold( KEY_CODES.W, 100 );
const sKey = new holdEvent.KeyboardKeyHold( KEY_CODES.S, 16.666 );
const aKey = new holdEvent.KeyboardKeyHold( KEY_CODES.A, 16.666 );
const dKey = new holdEvent.KeyboardKeyHold( KEY_CODES.D, 16.666 );

const leftKey = new holdEvent.KeyboardKeyHold(KEY_CODES.ARROW_LEFT, 100);
const rightKey = new holdEvent.KeyboardKeyHold(KEY_CODES.ARROW_RIGHT, 100);
const upKey = new holdEvent.KeyboardKeyHold(KEY_CODES.ARROW_UP, 100);
const downKey = new holdEvent.KeyboardKeyHold(KEY_CODES.ARROW_DOWN, 100);

export default function CreateCameraControls() {
    const cameraRef = useGlobalStore((state) => state.camera);
    const focusDrone = useGlobalStore((state) => state.focusDrone);
    const followDrone = useGlobalStore((state) => state.followDrone);
    const setFollowDrone = useGlobalStore((state) => state.setFollowDrone);

    const moveForward = useCallback(
    event => cameraRef.current?.forward(0.01 * event.deltaTime, true),
    [cameraRef]
    );
    const moveBack = useCallback(
    event => cameraRef.current?.forward(-0.01 * event.deltaTime, true),
    [cameraRef]
    );

    const moveLeft = useCallback(
        event => cameraRef.current?.truck(- 0.01 * event.deltaTime, 0, true)
    , [cameraRef])

    const moveRight = useCallback(
        event => cameraRef.current?.truck(0.01 * event.deltaTime, 0, true)
        , [cameraRef])

    const rotateLeft = useCallback(
        event => cameraRef.current?.rotate( - 0.1 * MathUtils.DEG2RAD * event.deltaTime, 0, true ),
        [cameraRef]
        );
    const rotateRight = useCallback(
    event => cameraRef.current?.rotate( 0.1 * MathUtils.DEG2RAD * event.deltaTime, 0, true ),
    [cameraRef]
    );

    const rotateUp = useCallback(
        event => cameraRef.current?.rotate( 0, - 0.05 * MathUtils.DEG2RAD * event.deltaTime, true )
    , [cameraRef])

    const rotateDown = useCallback(
        event => cameraRef.current?.rotate( 0, 0.05 * MathUtils.DEG2RAD * event.deltaTime, true )
        , [cameraRef])
        

    useEventListener(wKey, 'holding', moveForward);
    useEventListener(sKey, 'holding', moveBack);
    useEventListener(aKey, 'holding', moveLeft);
    useEventListener(dKey, 'holding', moveRight);
    useEventListener(leftKey, 'holding', rotateLeft);
    useEventListener(rightKey, 'holding', rotateRight);
    useEventListener(upKey, 'holding', rotateUp);
    useEventListener(downKey, 'holding', rotateDown);

    useControls(
        {
            "Camera Movement": 
                folder(
                    {"Focus Drone": button(() => focusDrone()) },
                    {collapsed: false, order: 1}
                )
        }, [focusDrone]
    );


    useControls({
        "Settings": folder({
        follow_drone: {
            value: followDrone,
            onChange: value => setFollowDrone(value),
        }
    }, {collapsed: true, order: 2}
    )});

    return (
        <CameraControls 
            ref={cameraRef}
            verticalDragToForward={true}
            mouseButtons={{
                left: ThreeCameraControls.ACTION.TRUCK, // truck
                right: ThreeCameraControls.ACTION.ROTATE, // rotate
                wheel: ThreeCameraControls.ACTION.DOLLY, // dolly
              }}
            draggingSmoothTime={0.075}
            makeDefault
        />
    )
}
