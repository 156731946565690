import {useGlobalStore} from './zustandStore.js'
import { Tooltip } from 'react-tooltip'
import { useUpdateState, getTsFromArrayData } from "./hooks/useUpdateState.jsx";

// Icons
import {BsWifi, BsWifiOff, BsCameraVideoOffFill, BsFillEyeFill, BsFillEyeSlashFill} from 'react-icons/bs';
import { MdLteMobiledata, Md4gMobiledata,
   Md3gMobiledata, MdSignalCellularOff,
    MdHouse, MdForest, MdOutlineSportsEsports, MdHealthAndSafety,
     MdBattery5Bar, MdSatelliteAlt,
     MdSpeed, MdHeight } from "react-icons/md";
import {GiFilmProjector} from 'react-icons/gi'

import './index.css';


export function BatteryIndicator() {
    const dataStore = useGlobalStore((state) => state.dataStore);
    const batteryData = dataStore?.battery?.data;

    const currentStep = useUpdateState({dataStore: batteryData, getTs: getTsFromArrayData});

    if (!batteryData) return <></>;
    
    const [ts, red_percent, yellow_percent, green_percent, battery_percent,
       battery_sec, ...cells]  = batteryData[currentStep];

    return (
      <div className="statusBar-item">
      <BatteryOverview 
      percentage={battery_percent.toFixed(0)} 
      seconds={battery_sec.toFixed(0)}
      cells={cells}
      />
        <div className="border border-dark w-45 h-100 m-1 p-0" style={{width: "250px"}}>
            <IndicatorSegment key="red" color="red" size={red_percent.toFixed(0)} tooltip="Emergency landing reserve"/>
            {
                (yellow_percent > 0) &&
                <IndicatorSegment key="yellow" color="yellow" size={yellow_percent.toFixed(1)} tooltip="Return To Home reserve"/>
            }
           {
            (green_percent > 0) &&
            <IndicatorSegment key="green" color="green" size={green_percent.toFixed(1)} tooltip="Free flight battery"/>
            }
        </div>
      </div>
    );
}

function printTime(seconds) {
  let minutes = Math.floor(seconds / 60);
  let secs = Math.floor(seconds % 60);
  secs = String(secs).padStart(2, '0');
  return `${minutes}:${secs}`;
}

function BatteryOverview({percentage, seconds, cells}) {
  const voltages = cells.slice(0,4);
  const current = cells[4];
  return (
    <div>
      <MdBattery5Bar size="40" data-tooltip-id={"battery-cells"}/>
      <Tooltip id="battery-cells">
        <div>Battery cell charges: </div>
        {voltages.map((voltage, index) => <div key={index}>{voltage.toFixed(2)}V</div>)}
        {current &&  <div>Current drawn: {current.toFixed(2)}A</div>}
      </Tooltip>

      <span className="text-div" data-tooltip-id={"battery-percent"}>{percentage}%</span>
      <Tooltip id="battery-percent" content="Remaining battery percentage"/>

      <span className="text-div" data-tooltip-id={"battery-time"}>{printTime(seconds)}s</span>
      <Tooltip id="battery-time" content="Remaining user facing time"/>
    </div>
  );
}

function IndicatorSegment({color, size, tooltip}) {
    const base_style = {
        height: '20px',
        display: 'inline-block',
        textAlign: 'center'
    };
    return (
      <>
        <div className={`m-0 p-0`} data-tooltip-id={"my-tooltip"} data-tooltip-content={tooltip}
            style={{
                width: `${size}%`,
                backgroundColor: color,
                ...base_style
            }}> 

            {size}%
        </div>
        <Tooltip id="my-tooltip" />
        </>
    );
}

function ComunicationState() {
  const dataStore = useGlobalStore((state) => state.dataStore);
  const comData = dataStore?.communication?.data
  const currentStep = useUpdateState({dataStore: comData, getTs: getTsFromArrayData});

  if (!comData) return <></>;

  const [ts, ch1, ch2, ch3, lte] = comData[currentStep];

  return (<div className="statusBar-item">
  <WifiStatus wifi={Boolean(ch1)}/>
  <MobileStatus lte={lte} ch2={Boolean(ch2)}/>
  <VideoStatus ch3={Boolean(ch3)}/>
  </div>)
}

function WifiStatus({wifi}) {
  if (wifi) {
    return (
      <div className="d-flex flex-column align-items-center icon-with-underline-sc">
        <BsWifi className="success-clr" size="40" data-tooltip-id="wifi"/>
        <Tooltip id="wifi">Wifi between IPAD and drone <span className="success-clr">Connected</span></Tooltip>
      </div>);
  } else {
    return (
      <div className="d-flex flex-column align-items-center icon-with-underline-err" > 
      <BsWifiOff color="red" size="40"  data-tooltip-id="wifi"/>
      <Tooltip id="wifi">Wifi between IPAD and drone <span className="danger-clr">NOT Connected</span></Tooltip>
    </div>);
  }
}

function MobileStatus({lte, ch2}) {
  let mobile_network;
  if (lte === 1) {
    mobile_network = (<MdLteMobiledata size="40" className="success-clr"/>);
  } else if (lte === 2) {
    mobile_network = (<Md4gMobiledata size="40" className="success-clr"/>);
  } else if (lte === 3) {
    mobile_network = (<Md3gMobiledata size="40" className="success-clr"/>);
  } else if (lte === 4) {
    mobile_network = (<div className="text-danger">Edge</div>);
  } else if (lte === 5) {
    mobile_network = (<div className="text-danger">Edge</div>);
  } else {
    mobile_network = (<MdSignalCellularOff size="40" color="red"/>);
  }

  const tooltip_text = (
    <>
  <span>Mobile connection of the drone</span>
  <br></br>
  <span>Ipad connected through mobile network </span>
  <span className={ch2 ? "success-clr " : "text-danger"}> {(ch2) ? "succeeded" : "failed"} </span>
  </>
  );

  if (ch2) {
    return (<div>
        <div className="d-flex flex-column align-items-center icon-with-underline-sc" data-tooltip-id="mobile">
        {mobile_network}
        <Tooltip id="mobile">
        {tooltip_text}
        </Tooltip>
        </div>
      </div>)
  } else {
    return (
    <div className="d-flex flex-column align-items-center icon-with-underline-err" data-tooltip-id="mobile">
      {mobile_network}
      <Tooltip id="mobile">{tooltip_text}</Tooltip>
    </div>)
  }
}

function VideoStatus({ch3}) {
  if (ch3) {
    return (<div>
      <BsFillEyeFill className="success-clr" size="40" data-tooltip-id="video"/>
      <Tooltip id="video">Video link between app and drone <span className="success-clr">Active</span></Tooltip>
      </div>);
  } else {
    return (<div>
      <BsFillEyeSlashFill color="red" size="40" data-tooltip-id="video"/>
      <Tooltip id="video">Video link between app and drone <span className="danger-clr">NOT Active</span></Tooltip>
    </div>);
  }
}

const GPS_FIX_3D = 3;

function GPSStatus() {
  const dataStore = useGlobalStore((state) => state.dataStore);
  const gpsData = dataStore?.gps?.data
  const currentStep = useUpdateState({dataStore: gpsData, getTs: getTsFromArrayData});

  if (!gpsData) return <></>;

  const [ts, fix, rtk, sat] = gpsData[currentStep]

  const gps_success = (fix === GPS_FIX_3D);
  const gps_color = gps_success ? "success-clr" : "text-danger";
  let rtk_color;
  let rtk_text;
  switch (rtk) {
    case 2:
      rtk_color = "success-clr";
      rtk_text = "RTK FIX";
      break;
    case 1:
      rtk_color = "warning-bg";
      rtk_text = "Floating RTK";
      break;
    default:
      rtk_color = "text-danger";
      rtk_text = "No RTK";
      break;
  }

  return (
  <div className="statusBar-item">
    <div className="font-weight-bold text-center statusBar-item statusBar-inner-item" data-tooltip-id="gps">
      <div className={gps_color}> GPS </div>
      <MdSatelliteAlt size="40"/>
      <div>{sat}</div>
      <Tooltip id="gps"> 
        <span>GPS connection of the drone</span>
        <span className={gps_color}> {(gps_success) ? "succeeded" : "failed"} </span>
        <br></br>
        <span>Number of satellites: {sat}</span>
      </Tooltip>
    </div>
    <div className={rtk_color} data-tooltip-id="rtk">
      RTK
      <Tooltip id="rtk"> 
      <span>RTK connection state: </span>
      <span className={rtk_color}>{rtk_text}</span>
      </Tooltip>
    </div>
  </div>)
}

function DroneState() {
  const dataStore = useGlobalStore((state) => state.dataStore);
  const stateData = dataStore?.var_state?.data
  const currentStep = useUpdateState({dataStore: stateData, getTs: getTsFromArrayData});

  if (!stateData) return <></>;
  
  const [ts, indoor, collisionAvoidanceOn, dataRecording] = stateData[currentStep];
  return (
    <>
      <CollisionAvoidanceState collisionAvoidanceOn={collisionAvoidanceOn}/>
      <DataRecording dataRecording={dataRecording}/>
      <IndoorMode indoorMode={indoor}/>
    </>
  );
}

function CollisionAvoidanceState({collisionAvoidanceOn}) {
  const tooltip_text = (
    <Tooltip id="collision"> 
      <span>Collision avoidance is: </span>
      <span className={collisionAvoidanceOn ? "success-clr" : "text-danger"}> 
      {(collisionAvoidanceOn) ? "active" : "not active"} 
      </span>
    </Tooltip>
  );
  if (collisionAvoidanceOn) {
    return (
    <>
      <MdHealthAndSafety size="40" className="success-clr" data-tooltip-id="collision"/>
      {tooltip_text}
    </>
    );
  } else {
    return (
      <>
      <MdHealthAndSafety size="40" color="red" data-tooltip-id="collision"/>
      {tooltip_text}
    </>
    );
  }
}

function DataRecording({dataRecording}) {
  const tooltip_text = (
    <Tooltip id="data-recording"> 
      <span>Data recording is: </span>
      <span className={dataRecording ? "success-clr" : "text-danger"}> 
      {(dataRecording) ? "ON" : "OFF"} 
      </span>
    </Tooltip>
  );
  if (dataRecording) {
    return (
    <>
      <GiFilmProjector size="40" className="success-clr" data-tooltip-id="data-recording"/>
      {tooltip_text}
    </>
    );
  } else {
    return (
      <>
      <BsCameraVideoOffFill size="40" data-tooltip-id="data-recording"/>
      {tooltip_text}
    </>
    );
  }
}

function IndoorMode({indoorMode}) {
  if (indoorMode) {
    return (
    <>
    <MdHouse size="40" data-tooltip-id="indoor-mode"/>
    <Tooltip id="indoor-mode" content="Indoor mode"/>
    </>
    )
  } else {
    return (
    <>
    <MdForest size="40" data-tooltip-id="indoor-mode"/>
    <Tooltip id="indoor-mode" content="Outdoor mode"/>
    </>
    )
  }
}

function RemoteControllerState() {
  const dataStore = useGlobalStore((state) => state.dataStore);
  const stateData = dataStore?.remoteControllerOn?.data
  const currentStep = useUpdateState({dataStore: stateData, getTs: getTsFromArrayData});

  if (!stateData) return <></>;
  
  const [ts, remoteControllerOn] = stateData[currentStep];
  return (
    <>
      <RemoteController remoteControllerOn={remoteControllerOn}/>
    </>
  );
}

function RemoteController({remoteControllerOn}) {
  if (remoteControllerOn) {
    return (
    <>
    <MdOutlineSportsEsports size="40" className="success-clr" data-tooltip-id="remote-controller"/>
    <Tooltip id="remote-controller" content="Remote controller active"/>
    </>
    )
  } else {
    return (
    <>
    <MdOutlineSportsEsports size="40" data-tooltip-id="remote-controller"/>
    <Tooltip id="remote-controller" content="Remote controller not active"/>
    </>
    )
  }
}

function MovementIndicator() {
  const dataStore = useGlobalStore((state) => state.dataStore);

  const stateData = dataStore?.speed_alt?.data;
  const currentStep = useUpdateState({dataStore: stateData, getTs: getTsFromArrayData});
  if (!stateData) return <></>;

  const [ts, spd, alt] = stateData[currentStep];
  return (
    <div className="statusBar-item">
      <div data-tooltip-id="alt">
        <MdHeight size="40"/>
        {alt.toFixed(1)} m
      </div>
      <div data-tooltip-id="spd">
        <MdSpeed size="40"/>
        {spd.toFixed(1)} m/s
      </div>
      <Tooltip id="alt" content="Altitude in meters"/>
      <Tooltip id="spd" content="Velocity in m/s"/>
    </div>
  );
}
function formatFSMState(state) {
  const prefix = "IN_AIR_";
  if (state.startsWith(prefix)) {
    state = state.substring(prefix.length);
  }
  return state
}

function FSMState() {
  const dataStore = useGlobalStore((state) => state.dataStore);
  const enumDescriptor = dataStore?.enums
  const fsmState = dataStore?.fsm?.data
  const currentStep = useUpdateState({dataStore: fsmState, getTs: getTsFromArrayData});

  if (!fsmState || !enumDescriptor) return <></>;

  const current_state_id = fsmState[currentStep][1]
  const current_state_name = enumDescriptor["omp_node_base.fsm_state_id"][current_state_id]

  return (
    <div className="fsmstate " data-tooltip-id="fsm">
      <div className="flex-item-header">Drone State</div>
      <div className="flex-item-description">{formatFSMState(current_state_name)}</div>
      <Tooltip id="fsm">
        The drone operating modes:
        <ul>
        <li>STICK_INPUT or STICK_INPUT_COLLISION_AVOIDANCE_OFF, the user is controlling the drone directly</li>
        <li>INITIALIZING, ON_GROUND, IDLE the drone is idle</li>
        <li>TAKEOFF, LANDING, the collision avoidance is off in these modes</li>
        <li>LAND_IN_PLACE, the user triggered a land in place</li>
        <li>AUTO_RTL, AUTO_RTU, AUTO_LAND_IN_PLACE automatically carried out mission, because of low battery</li>
        <li>MISSION, RTL, RTU, EXPLORATION the drone is flying automated</li>
        <li>FS, there was an internal error, and the flight controller took over control </li>
        <li>EMERGENCY_LANDING, we ran out of battery or some internal error happened</li>
        </ul>
        </Tooltip>
    </div>
  )
}

const getTsFromMissionData = (row) => {
  return row.ts / 1e9;
}

function CurrentMission() {
  const dataStore = useGlobalStore((state) => state.dataStore);
  const enumDescriptor = dataStore?.enums
  const missionData = dataStore?.mission_segments?.data
  const currentStep = useUpdateState({dataStore: missionData, getTs: getTsFromMissionData});

  if (!enumDescriptor || !missionData) return <></>;
  const current_state_id = missionData[currentStep].type

  let current_state_name = "None"
  let mission_creator = "None"
  if (current_state_id !== 0) {
    const creator = missionData[currentStep].creator
    mission_creator = enumDescriptor["omp_node_base.omp_data.input.current_mission.creator"][creator]
    current_state_name = enumDescriptor["omp_node_base.omp_data.input.current_mission.type"][current_state_id]
  }

  return (
    <>
    <div className="fsmstate " data-tooltip-id="mission">
      <div className="flex-item-header">Mission</div>
      <div className="flex-item-description">{current_state_name}</div>
      <Tooltip id="mission">
        The drone operating modes:
        <ul>
        <li>TAP, drone approaches the double tapped point</li>
        <li>PAN, the drone turns around</li>
        <li>ZOOM, the drone approaches or backs off to have a better view</li>
        <li>SCAN, scanning mission</li>
        <li>RTX, return to home/user</li>
        <li>RESUME_SCAN, after battery swap approaching the point where the scan was paused</li>
        <li>EXPLORATION, exploring the area and scanning</li>
        </ul>
      </Tooltip>
    </div>
    <div className="fsmstate " data-tooltip-id="creator">
      <div className="flex-item-header">Creator</div>
      <div className="flex-item-description">{mission_creator}</div>
      <Tooltip id="creator">
        Mission creator:
        <ul>
          <li>APP: the mission was triggered by the user</li>
          <li>Oriole: the mission was triggered by the drone, eg. battery ran out and AUOT_RTH triggered</li>
        </ul>
      </Tooltip>
    </div>
    </>
  )
}

function OmpState() {
  return (
    <>
      <FSMState/>
      <CurrentMission/>
    </>
  )
}

export function StatusBar() {
  return (
  <div className="statusBar ">
    <MovementIndicator/>
    <GPSStatus/>
    <ComunicationState/>
    <BatteryIndicator/>
    <DroneState/>
    <RemoteControllerState/>
    <OmpState/>
  </div>);
}
